<template>
  <b-modal
    id="profileEditModal"
    no-close-on-backdrop
    v-model="showModal"
    size="xl"
    :scrollable="true"
    class="profile-edit-modal custom-modal"
    @show="onShown"
    @hide="onHidden"
  >
    <template #footer>
      <div/>
    </template>
    <template #title>
      <div>
        <span class="modal-title">Редагування:</span> <span class="modal-title__username">{{ profileUserName }}</span>
      </div>
    </template>
    <template #header-close>
      <img type="button" class="modal-closeButton" data-bs-dismiss="modal">
    </template>
    <template #default>
      <div v-if="person" class="modal-body">
        <div class="profile-edit-modal__fields-wrapper">
          <CustomInput
            id="nameInput"
            type="text"
            placeholder="Введи імʼя"
            :answer="value.realName"
            class="profile-edit-modal__input-name"
            @onUpdate="onInput('realName', $event)"
            @onFocusLeave="onInput('realName', $event)"
          >
            <p class="modal-editUserInfoBlock-title">Імʼя:</p>
          </CustomInput>
          <CustomInput
            id="emailInput"
            type="text"
            placeholder="Введи імейл"
            :answer="value.email"
            class="profile-edit-modal__input-email"
            @onUpdate="onInput('email', $event)"
            @onFocusLeave="onInput('email', $event)"
          >
            <p class="modal-editUserInfoBlock-title">E-mail:</p>
          </CustomInput>
          <CustomInput
            id="tgInput"
            type="text"
            placeholder="Введи Tg"
            :answer="value.tgLink"
            class="profile-edit-modal__input-vk"
            @onUpdate="onInput('tgLink', $event)"
            @onFocusLeave="onInput('tgLink', $event)"
          >
            <p class="modal-editUserInfoBlock-title">Tg:</p>
          </CustomInput>
          <CustomInput
            id="fbInput"
            type="text"
            placeholder="Введи fb"
            :answer="value.fbLink"
            class="profile-edit-modal__input-fb"
            @onUpdate="onInput('fbLink', $event)"
            @onFocusLeave="onInput('fbLink', $event)"
          >
            <p class="modal-editUserInfoBlock-title">Fb:</p>
          </CustomInput>
          <CustomInput
            id="discordInput"
            type="text"
            placeholder="Введи діскорд"
            :answer="value.discordTag"
            :pattern="'[0-9]{4}'"
            class="profile-edit-modal__input-discord"
            @onUpdate="onInput('discordTag', $event)"
            @onFocusLeave="onInput('discordTag', $event)"
          >
            <p class="modal-editUserInfoBlock-title">Discord:</p>
          </CustomInput>
        </div>
        <div class="profile-edit-modal__buttons-wrapper">
          <CustomButton
            value="Зберегти"
            @clicked="onConfirmClick"
          />

          <CustomButton
            isClear
            value="Скасувати"
            @clicked="onCancelClick"
          />
        </div>
        <template v-if="$can('admin')">
          <div class="row modal-splitterLine"></div>

          <div class="profile-edit-modal__calendar-wrapper omhms-content-block">
            <div class="profile-info-row">
              <Calendar
                :active="true"
                :years="[2024, 2025]"
                :payments="payments"
                @select="onDateSelected"
              />
            </div>
            <PaymentPreview
              v-if="selectedDateId !== null"
              :date="date"
              v-click-outside="onClickOutside"
            />
            <div v-else class="profile-edit-modal__placeholder">
              <span class="omhms-text omhms-text-regular">Для перегляду подробиць, <br>вибери дату в календарі оплат.</span></div>
          </div>
        </template>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Calendar from "@/components/paymentCalendar/PaymentCalendar"
import PaymentPreview from "@/components/paymentPreview/index"
import CustomInput from "@/components/CustomInput.vue"
import CustomButton from "@/components/CustomButton"

import { mapGetters, mapActions } from "vuex"
import vClickOutside from 'click-outside-vue3'

export default {
  name: "ProfileEditModal",
  components: {
    Calendar,
    PaymentPreview,
    CustomInput,
    CustomButton,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      selectedDateId: null,
      date: null,
      showModal: false,
      value: {
        realName: "",
        email: "",
        tgLink: "",
        discordTag: "",
        fbLink: "",
      }
    }
  },
  computed: {
    ...mapGetters({
      payments: "payment/payments",
      profile: "profile/profile",
      user: "auth/user",
    }),

    isPreview() {
      return this.$route.params?.username
    },

    profileUserName() {
      return this.isPreview ? this.$route.params.username : this.user.userName
    },

    person() {
      return this.profile?.person
    }
  },
  methods: {
    ...mapActions({
      getPayments: "payment/get",
      updatePerson: "profile/update",
      getProfile: "profile/get",
      getSelfProfile: "profile/getSelf",
    }),

    async getProfileData() {
      await (this.isPreview) ? this.getProfile(this.$route.params.username) : this.getSelfProfile()
    },

    onDateSelected(date) {
      this.selectedDateId = date.id
      this.date = date
    },

    onShown() {
      this.syncPerson()
      this.getPayments(this.person.id)
    },

    onHidden() {
      this.$emit("modalHidden")
    },

    onInput(field, value) {
      if (!value) {
        return
      }
      this.value[field] = value
    },

    onClickOutside(e) {
      if (e.target.id?.includes("month")) {
        return
      }
      this.selectedDateId = null
    },

    syncPerson() {
      Object.keys(this.value).forEach(key => {
        this.value[key] = this.person[key]
      })
    },

    async onConfirmClick() {
      await this.updatePerson({
        id: this.person.id,
        ...this.value,
      })
      this.getProfileData()
      this.showModal = false
    },

    onCancelClick() {
      this.syncPerson()
    },
  }
}
</script>