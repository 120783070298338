<template>
  <div class="main-layout profile-user">
    <PageHeader :title="title" :hide-logout="Boolean(isPreview)"/>
    <div class="profile-content container">
      <div v-if="error" class="row justify-content-center omhms-content-block error-block_no-record">
        <span class="omhms-text omhms-text-h2-regular">
          Такого гравця немає у нашій базі!
        </span>
      </div>
      <template v-else>
        <div class="container">
          <div class="row justify-content-center profile-content-notifications">
            <template
              v-for="(notification, index) in notifications"
              :key="index"
            >
              <CustomNotification :notification="notification" />
            </template>
          </div>
          <div class="row justify-content-start omhms-content-block">
            <div class="col-12">
              <span class="omhms-text omhms-text-h2-regular">Основна інформація</span>
            </div>

            <ProfileMainInfo />

            <div v-if="profileHasPerson && canSeeProfileAdminData" class="profile-info col-xxl-4 order-xxl-2 order-lg-3 col-md-6 col-12">
              <div class="row">
                <span class="omhms-text omhms-text-h4-regular">Контакти:</span>
              </div>
              <div class="profile-info-row">
                <div class="profile-info-row__title profile-info-row__title__second">Email:</div>
                <div class="profile-info-row__text">{{ profile.person.email }}</div>
              </div>
              <div class="profile-info-row" >
                <div class="profile-info-row__title profile-info-row__title__second">Telegram:</div>
                <div class="profile-info-row__text" v-if="profile.person.tgLink">{{ profile.person.tgLink }}</div>
                <div class="profile-info-row__text__noInfo" v-else>Не вказано</div>
              </div>
              <div class="profile-info-row" >
                <div class="profile-info-row__title profile-info-row__title__second">Discord:</div>
                <div class="profile-info-row__text" v-if="profile.person.discordTag">{{ profile.person.discordTag }}</div>
                <div class="profile-info-row__text__noInfo" v-else>Не вказано</div>
              </div>
              <div class="profile-info-row" >
                <div class="profile-info-row__title profile-info-row__title__second">FB:</div>
                <div class="profile-info-row__text" v-if="profile.person.fbLink">{{ profile.person.fbLink }}</div>
                <div class="profile-info-row__text__noInfo" v-else>Не вказано</div>
              </div>
            </div>

            <div v-if="profileHasPerson && canSeeProfileAdminData" class="profile-info col-xxl-4 order-xxl-3 order-lg-2 col-lg-6 col-12">
              <div class="row">
                <span class="omhms-text omhms-text-h4-regular">Календар перепусток:</span>
              </div>
              <div class="profile-info-row">
                <Calendar
                  :years="[2022, 2023, 2024, 2025]"
                  :payments="payments"
                />
              </div>
              <span class="omhms-text omhms-text-caption legendText">* В календарі відображаються перепустки період дії яких припадає на цей календарний місяць. Тобто якщо перепустка з 16 числа по 15 число настпного місяця - буде підсвічено два. Уважно слідуйте за своєю перепусткою.</span>
            </div>
          </div>

          <div v-if="profileHasCharacters" class="row justify-content-start omhms-content-block">
            <span class="omhms-text omhms-text-h2-regular">Ігрові персонажі</span>
            <div class="profile-characters row">
              <ProfileCharacterCard
                v-for="(character, index) in profileCharacters"
                :key="`character-${index}`"
                :character="character"
                :index="index"
              />
              <div
                class="profile-characters__item col-12 col-md-6 col-lg-4 col-xl-3"
                data-bs-toggle="modal"
                data-bs-target="#profileAddCharacterModal"
              >
                <div class="profile-character profile-character_add">
                  <img src="@/assets/images/svg/add_user.svg"/>
                  <span>Додати персонажа</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>

  <ProfileEditModal @modalHidden="onEditModalHidden"/>
  <ProfileAddCharacter @characterAdded="onCharacterAdded"/>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

import PageHeader from "@/components/PageHeader"
import ProfileEditModal from "@/components/modals/ProfileEditModal"
import ProfileAddCharacter from "@/components/modals/ProfileAddCharacter"
import CustomNotification from "@/components/CustomNotification"
import Calendar from "@/components/paymentCalendar/PaymentCalendar"
import ProfileCharacterCard from "@/components/profile/ProfileCharacterCard"
import ProfileMainInfo from "@/components/profile/ProfileMainInfo"

export default {
  name: 'User',
  components: {
    PageHeader,
    CustomNotification,
    Calendar,
    ProfileEditModal,
    ProfileCharacterCard,
    ProfileAddCharacter,
    ProfileMainInfo,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      isLoggedIn: "auth/check",
      notifications: "notifications/notifications",
      registration: "registration/form",
      profile: "profile/profile",
      error: "profile/error",
    }),

    payments() {
      return this.profile?.person?.payments ?? []
    },

    isPreview() {
      return this.$route.params?.username
    },

    title() {
      return this.isPreview ? `Профіль ${this.$route.params.username}` : 'Особистий кабінет'
    },

    profileUserName() {
      return this.isPreview ? this.$route.params.username : this.user.userName
    },

    profileHasCharacters() {
      return this.profile && this.profile.characters && this.profile.characters.length
    },

    profileHasPerson() {
      return this.profile?.person?.id
    },

    profileCharacters() {
      return this.profile?.characters ?? []
    },

    canSeeProfileAdminData() {
      return this.isPreview ? this.$can('admin') : true
    },

    payButtonTitle() {
      return 'Оплатити'
    },
  },
  created() {
    this.getProfileData()
  },
  methods: {
    ...mapActions({
      getUserForm: "registration/new",
      getNotifications: "notifications/get",
      clearNotifications: "notifications/clear",
      getProfile: "profile/get",
      getSelfProfile: "profile/getSelf",
    }),

    async getProfileData() {
      this.$wait.start("profile.get")
      if (this.isPreview) {
        await this.getProfile(this.$route.params.username)
      } else {
        this.getNotifications()
        await this.getSelfProfile()
      }
      this.$wait.end("profile.get")
    },

    onCharacterAdded() {
      this.getProfileData()
    },

    registrationGet() {
      this.$router.push({name: "registration"})
    },

    onEditModalHidden() {
      this.getProfileData()
    },
  },
  unmounted() {
    this.clearNotifications()
  },
}
</script>
